<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Clientes" :class="{ tabs: empresaEdita.id }">
          <form-adicionar-relacao-cliente
            @addedCliente="addedCliente"
            v-if="showForm || irParaCadastro"
          ></form-adicionar-relacao-cliente>
          <form-editar-empresa-relacao
            v-else-if="editarCliente"
            v-model="clienteSelecionado"
            @atualizar-contatos="salvarContatos"
          />
          <listar-empresas-relacao
            v-else
            v-model="clientes"
            :tela="permission"
            title="Clientes/Cooperativas registrados"
            titleNew="Novo Cliente"
            campoId="cliente_id"
            :tipo="tipo"
            @update="atualizarListaClientes"
            @delete="confirmaExcluirCliente"
            @showForm="showForm = true"
            @editar="editar"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListarEmpresasRelacao from '@/components/empresaRelacao/ListarEmpresasRelacao'
import FormEditarEmpresaRelacao from '@/components/empresaRelacao/editar/FormEditarEmpresaRelacao.vue'
import FormAdicionarRelacaoCliente from '@/components/administrativo/cliente/adicionarCliente/FormAdicionarRelacaoCliente.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import Perfil from '@/utils/perfil'

export default {
  props: {
    irParaCadastro: {
      default: false,
    },
  },
  data() {
    return {
      tipo: Perfil.Cliente,
      Sequences,
      showForm: false,
      editarCliente: false,
      page: {
        title: 'Gerenciamento de clientes',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
        },
        {
          text: 'Cliente',
          disabled: true,
        },
      ],
      clienteSelecionado: {},
      empresaEdita: {},
    }
  },
  components: {
    ListarEmpresasRelacao,
    FormEditarEmpresaRelacao,
    FormAdicionarRelacaoCliente,
  },
  computed: {
    ...mapState('clientes', ['clientes']),

    permission() {
      return Sequences.Clientes.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      this.getClientes()
    }
  },
  methods: {
    ...mapActions('clientes', [
      'getCliente',
      'getClientes',
      'adicionarCliente',
      'excluirCliente',
      'atualizarContatos',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async atualizarListaClientes() {
      try {
        await this.getClientes()
      } catch (error) {
        this.errorMessage('Erro ao atualizar lista de clientes:' + error)
      }
    },
    async confirmaExcluirCliente(cliente) {
      try {
        this.excluirCliente(cliente)
        this.successMessage('Empresa excluída com sucesso')
      } catch (error) {
        this.errorMessage('Erro ao excluir empresa')
      }
    },
    async editar(cliente) {
      try {
        const data = await this.getCliente({
          public_id: cliente.public_id,
          empresa: { public_id: cliente.empresa_id },
        })
        this.clienteSelecionado = data
        this.editarCliente = true
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async addedCliente(cliente) {
      try {
        cliente.public_id
        this.getClientes()
        this.successMessage('Empresa adicionada com sucesso.')
        this.showForm = false
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    async adicionarEmpresaRelacao(empresa) {
      try {
        await this.adicionarCliente(empresa)
        this.getClientes()
        this.successMessage('Empresa adicionada com sucesso.')
        this.showForm = false
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    async salvarContatos(contatos) {
      try {
        await this.atualizarContatos({
          cliente: this.clienteSelecionado.public_id,
          contatos,
        })
        this.successMessage('Contato atualizado com sucesso')
        this.getClientes()
      } catch (e) {
        this.errorMessage('Erro ao atualizar lista de contatos')
      }
    },
  },
}
</script>

<style></style>
