<template>
  <div class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados">
        Dados da empresa <v-icon>mdi-home-city</v-icon></v-tab
      >
      <v-tab href="#contatos"> Contatos <v-icon>mdi-phone</v-icon></v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-empresa-relacao
            v-model="value"
            @atualiza-RNTRC="atualizaRNTRC"
            @close="close"
          />
        </v-tab-item>
        <v-tab-item value="contatos">
          <dados-contato
            v-model="value"
            @atualizar-contatos="atualizarContatos"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosEmpresaRelacao from './DadosEmpresaRelacao.vue'
import DadosContato from './DadosContato.vue'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: { DadosEmpresaRelacao, DadosContato },
  data() {
    return {
      tab: null,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    atualizarContatos(contatos) {
      this.$emit('atualizar-contatos', contatos)
    },
    atualizaRNTRC(RNTRC) {
      this.$emit('atualiza-RNTRC', RNTRC)
    },
  },
}
</script>

<style></style>
