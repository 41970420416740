<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Sobre a empresa
      </h3>
      <span class="red--text" v-if="!canEdit"
        >Dados editáveis apenas pelo administrador da empresa</span
      >
      <span class="red--text" v-else
        >Apenas dados do RNTRC podem ser editidos</span
      >
    </v-card-text>
    <validation-observer ref="observer">
      <v-form :readonly="!canEdit">
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="Nome Fantasia"
                rules="required"
              >
                <v-text-field
                  label="Nome Fantasia"
                  :error-messages="errors"
                  v-model="dadosTransportadora.business_name"
                  disabled
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <v-text-field
                  label="Razão Social"
                  :error-messages="errors"
                  v-model="dadosTransportadora.trade_name"
                  disabled
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <v-text-field
                label="CNPJ"
                readonly
                v-model="dadosTransportadora.cnpj"
                disabled
                v-mask="['##.###.###/####-##']"
              />
              <v-text-field
                disabled
                label="IE"
                v-model="dadosTransportadora.ie"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pa-5">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Endereço
          </h3>
          <v-row class="mt-3">
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Endereço"
                v-model="dadosTransportadora.address"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Bairro"
                v-model="dadosTransportadora.district"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Cidade"
                v-model="dadosTransportadora.city"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="CEP"
                v-mask="['#####-###']"
                v-model="dadosTransportadora.zip_code"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-select
                label="Estado"
                :items="estados"
                v-model="dadosTransportadora.state"
                disabled
                item-text="nome"
                item-value="sigla"
              ></v-select>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Telefone"
                v-model="dadosTransportadora.phone"
                disabled
                v-mask="['(##) #####-####', '(##) ####-####']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.rntrc_numero"
                :readonly="false"
                label="Numero RNTRC"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-autocomplete
                label="Tipo RNTRC"
                :items="['CTC', 'ETC', 'TAC']"
                v-model="value.rntrc_tipo"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.rntrc_expiration_date"
                label="Data de validade RNTRC"
                persistent-hint
                type="date"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="canEdit">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapState } from 'vuex'

import UtilsApi from '@/services/utils/utils.api'

export default {
  mixins: [validationMixin],

  props: {
    transportadora: Object,
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dadosTransportadora: this.value,
      estados: [],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    canEdit() {
      return true
      // return (
      //   this.value.adminGroup == this.empresaAtual.grupo.public_id ||
      //   this.empresaAtual.gate
      // )
    },
  },
  watch: {
    value(val) {
      Object.assign(this.dadosTransportadora, val)
      this.$forceUpdate()
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.$emit('atualiza-RNTRC', {
        public_id: this.value.public_id,
        // transportadora_id: this.value.transportadora_id,
        rntrc_numero: this.value.rntrc_numero,
        rntrc_tipo: this.value.rntrc_tipo,
        rntrc_expiration_date: this.value.rntrc_expiration_date,
      })
    },
    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },
  },
  created() {
    this.getEstados()
  },
}
</script>

<style></style>
